<template>
  <div :class="activeFont">
    <Customizer @onLogo="changeLogo" @toggle="sidebarMini" @animationChange="routerAnimationChange" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SmallSidebar v-if="$route.name === 'dashboard.home-5'" :logo="SmallSidebarLogo" @toggle="sidebarMini" />
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText" @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar :homeURL="{ name: 'ministry.dashboard' }" :sidebarGroupTitle="sidebarGroupTitle" @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item d-flex" v-nav-toggle>
              <lang-button/>
            </li>
            <li class="nav-item" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect" v-if="$store.state.Auth.activeRoleId === 1">
                <i class="ri-notification-line"></i>
                <span class="bg-danger dots"></span>
              </a>
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">{{ $t('nav.allNotifications') }}<small class="badge  badge-light float-right pt-1">4</small></h5>
                    </div>
                    <a href="#" class="iq-sub-card" v-for="(item, index) in notificationList" :key="index">
                      <div class="media align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" :src="item.image" alt="img">
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ item.name }}</h6>
                          <small class="float-right font-size-12">{{ item.date }}</small>
                          <p class="mb-0">{{ item.description.substring(0,40) + '...' }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
        <template slot="right">
          <SiteSettings :authServiceBaseUrl="authServiceBaseUrl" :authUser="authUser" :userProfile="userProfile"/>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <b-container fluid="" v-if="!notBookmarkRouts.includes($route.name)">
        </b-container>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>
<style>
.ministry-iq-sidebar-menu .router-link-active {
  color: var(--iq-white) !important;
  background: var(--brand-secondary-color) !important;
  border-radius: 0px !important;
}
</style>
<script>
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { core } from '../config/pluginInit'
import { mapGetters } from 'vuex'
import Sidebar from '../components/core/sidebars/SidebarMinistryDashboard'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import SiteSettings from './Components/SitePreferenceSetting'
import profile from '../assets/images/user/user-1.jpeg'
import Logo from '../assets/images/logo.png'
import Customizer from './Components/Customizer'
import SmallSidebar from '../components/core/sidebars/SmallSidebar'
import LayoutFooter from './Components/LayoutFooter'
import { EventBus } from '@/EventBusLayout'
// import logout from '@/Utils/logout'
import LangButton from './Components/LangButton'

export default {
  name: 'VerticleLayout',
  components: {
    SiteSettings,
    LayoutFooter,
    Customizer,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    LangButton
  },
  watch: {
    currentLocale (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.changeLangActiveMenus(newVal)
      }
    }
  },
  mounted () {},
  created () {
    if (this.authUser === null) {
      this.logout()
    }
    this.getSidebarMenus()
    this.$root.$refs.ExternalUserLayout = this
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    dataTest () {
      return this.$store.state.ExternalUserIrrigation
    },
    dataExternalUser () {
      return this.$store.state.ExternalUserTraining
    },
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-3':
          show = false
          break
      }
      return show
    },
    currentLocale () {
      return this.$i18n.locale
    },
    notificationList () {
      return [
        { image: require('../assets/images/user/user-01.jpg'), name: this.currentLocale === 'en' ? 'Nikhil' : 'নিখিল', date: this.currentLocale === 'en' ? '23 years Ago' : '২৩ বছর আগে', description: this.currentLocale === 'en' ? 'All Farmers Enjoy digital paltform' : 'সমস্ত কৃষক ডিজিটাল প্ল্যাটফর্ম উপভোগ করুন' },
        { image: require('../assets/images/user/user-05.jpg'), name: this.currentLocale === 'en' ? 'Arif' : 'আরিফ', date: this.currentLocale === 'en' ? '23 years Ago' : '২৩ বছর আগে', description: this.currentLocale === 'en' ? 'Developed By Syntech Solution Ltd' : 'সিনটেক সলিউশন লিমিটেড দ্বারা তৈরি' }
      ]
    },
    activeFont: function () {
      return this.$i18n.locale === 'en' ? 'font-en' : 'font-bn'
    }
  },
  data () {
    return {
      langLabel: this.$i18n.locale === 'bn' ? this.$t('globalTrans.en') : this.$t('globalTrans.bn'),
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      verticalMenu: [],
      userProfile: profile,
      onlyLogo: true,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: Logo,
      SmallSidebarLogo: Logo,
      isLoad: false,
      notBookmarkRouts: [],
      authServiceBaseUrl: authServiceBaseUrl
    }
  },
  methods: {
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    logout () {
      // logout()
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    getLimitedAuthUserName (name) {
      const fullName = name.split(' ')
      const len = fullName.length
      let obtainedName = ''
      for (let i = 0; i < len; i++) {
        const part = obtainedName + ' ' + fullName[i]
        if (part.length <= 10) {
          obtainedName += fullName[i] + ' '
        }
      }
      if (obtainedName === '') {
        obtainedName = fullName[0].substring(0, 7) + '...'
      }
      return obtainedName.trim()
    },
    getSidebarMenus () {
      RestApi.getData(commonServiceBaseUrl, 'ministry-dashboard-menu/sidebar-menus', null).then(response => {
        if (response.data.length === 0) {
          this.verticalMenu = []
          return
        }
        this.setSidebarMenus(response.data)
      })
    },
    setSidebarMenus (data) {
      const lang = this.$i18n.locale
      const menus = data.map(menuItem => {
        let url = menuItem.url.trim()
        url = url.startsWith('/') ? url : `/${url}`
        const lastSegment = url.split('/')[3]
        return {
          title: menuItem.menu_name,
          title_bn: menuItem.menu_name_bn,
          name: lang === 'en' ? menuItem.menu_name : menuItem.menu_name_bn,
          is_heading: false,
          is_active: true,
          link: url,
          class_name: '',
          is_icon_class: true,
          icon: 'ri-record-circle-line',
          image_name: lastSegment
        }
      })
      this.verticalMenu = menus
      EventBus.$emit('MINISTRY_DASHBOARD_NAMES', menus)
    },
    changeLangActiveMenus (lang) {
      const menus = this.verticalMenu.map(item => {
        return Object.assign({}, item, {
            name: lang === 'en' ? item.title : item.title_bn
          })
      })
      this.verticalMenu = menus
      EventBus.$emit('MINISTRY_DASHBOARD_NAMES', menus)
    }
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>

<style scoped>
  .profile-tmp-img img {
    width: 48px;
  }
</style>
